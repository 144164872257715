import $api from "@/api";
const jWeixin = require("weixin-js-sdk");

export default {
  updateShareData(params = {}) {
    $api("/official/page/wechat_update_share_data", params).then(({ data }) => {
      jWeixin.ready(() => {
        console.log("配置公众号分享信息");
        const shareInfo = data.share;
        jWeixin.updateAppMessageShareData({
          title: shareInfo.title, // 分享标题
          desc: shareInfo.desc, // 分享描述
          link: shareInfo.link,
          imgUrl: shareInfo.imgUrl, // 分享图标
        });
        jWeixin.updateTimelineShareData({
          title: shareInfo.title, // 分享标题
          desc: shareInfo.desc, // 分享描述
          link: shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: shareInfo.imgUrl, // 分享图标
        });
      });

      jWeixin.error(function(res) {
        console.log(res);
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });

      jWeixin.config(data.config);
    });
  },
};
