import request from "@/api/request";
export default {
  listSlide(data) {
    return request({
      //实际地址
      url: "/system/system_user/login",
      method: "post",
      //接口入参解耦
      data: {
        username: data.username,
        password: data.password,
      },
    }).then((data) => {
      //出参解耦
      console.log(data);
      return data;
    });
  },
};
