import bezier from "@/assets/bezier";
import Vue from "vue";
export default {
  data() {
    return {
      $prototype: Vue.prototype,
      fullScreenVideo: {
        load: false,
        show: false,
        src: "",
      },
    };
  },
  methods: {
    wechatShareInfo(params = {}) {
      //在微信中打开
      if (
        navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
        "micromessenger"
      ) {
        console.log("定制微信分享内容：", params);
        this.$wechat.updateShareData(params);
      }
    },
    stripHTML(html) {
      return html
        .replace(/<(?:.|\s)*?>/g, "")
        .replace(/&nbsp;/gi, "")
        .replace(/&amp;/gi, "");
    },
    scrollDownPage(page = 1) {
      console.log(page);
      var startScrollTop = document.documentElement.scrollTop;
      const endScrollTop =
        document.documentElement.clientHeight * page +
        document.documentElement.scrollTop;
      const points = bezier.getBezierPoints(
        100,
        [startScrollTop, 0],
        [endScrollTop, 0],
        // [0, parseInt((endScrollTop / 3) * 2)],
        [endScrollTop, 0]
      );

      function to(points) {
        setTimeout(() => {
          const point = points.shift();
          if (!point) {
            return;
          }
          window.scrollTo(point[1], point[0]);
          to(points);
        }, 1);
      }
      to(points);

      // bezier
      //   .getBezierPoints(5000, [0, startScrollTop], [0, endScrollTop])
      //   .forEach((d) => {
      //     d = d.map((e) => parseInt(e, 10));
      //     setTimeout(() => {
      //       window.scrollTo(0, d[1]);
      //     }, 100);
      //   });
    },

    //判断是否是手机
    isMobile() {
      var isMobile = {
        Android: function() {
          return navigator.userAgent.match(/Android/i) ? true : false;
        },
        BlackBerry: function() {
          return navigator.userAgent.match(/BlackBerry/i) ? true : false;
        },
        iOS: function() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i) ? true : false;
        },
        Windows: function() {
          return navigator.userAgent.match(/IEMobile/i) ? true : false;
        },
        any: function() {
          return (
            isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Windows()
          );
        },
      };

      return isMobile.any(); //是移动设备
    },
    lookFullScreenVideo(src) {
      const that = this;
      function checkFull() {
        // 判断浏览器是否处于全屏状态 （需要考虑兼容问题）

        var isFull =
          document.mozFullScreen ||
          document.fullScreen ||
          document.webkitIsFullScreen ||
          document.webkitRequestFullScreen ||
          document.mozRequestFullScreen ||
          document.msFullscreenEnabled;
        if (typeof that.$refs.fullScreenVideo == "object") {
          isFull =
            isFull || that.$refs.fullScreenVideo.webkitDisplayingFullscreen;
        }

        if (isFull === undefined) {
          isFull = false;
        }
        return isFull;
      }

      this.fullScreenVideo.load = true;
      var interval = setInterval(() => {
        if (this.fullScreenVideo.load) {
          return;
        }
        if (!checkFull() && this.fullScreenVideo.show) {
          if (this.$refs.fullScreenVideo) {
            this.$refs.fullScreenVideo.pause();
          }
          this.fullScreenVideo.show = false;
          this.$root.eventHub.$emit("MediaStop", {});

          clearInterval(interval);
        }
      }, 50);

      this.fullScreenVideo.show = true;
      this.fullScreenVideo.src = src;
      this.$root.eventHub.$emit("MediaPlay", {});

      setTimeout(() => {
        try {
          const method = this.$refs.fullScreenVideo.requestFullscreen
            ? "requestFullscreen"
            : "webkitEnterFullScreen";
          this.$refs.fullScreenVideo[method]().then(() => {
            this.$refs.fullScreenVideo.play();
          });
        } catch (e) {
          console.log(e);
        }

        setTimeout(() => {
          this.fullScreenVideo.load = false;
        }, 100);
      }, 200);
    },
    isFullScreenVideo() {
      var isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled;
      if (typeof this._data.$prototype.$app.$refs.fullScreenVideo == "object") {
        isFull =
          isFull ||
          this._data.$prototype.$app.$refs.fullScreenVideo
            .webkitDisplayingFullscreen;
      }

      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
    showFullScreenVideo(src) {
      //监听全屏状态
      this._data.$prototype.$app.fullScreenVideoLoad = true;
      var interval = setInterval(() => {
        console.log("interval run");
        if (this._data.$prototype.$app.fullScreenVideoLoad) {
          return;
        }

        if (!this.isFullScreenVideo()) {
          console.log("interval 非全屏状态");

          if (this._data.$prototype.$app.$refs.fullScreenVideo) {
            this._data.$prototype.$app.$refs.fullScreenVideo.pause();
          }
          this.$root.eventHub.$emit("MediaStop", {});
          this._data.$prototype.$app.fullScreenVideoLoad = false;

          clearInterval(interval);
        }
      }, 20);

      this._data.$prototype.$app.fullScreenVideoSrc = src;
      this.$root.eventHub.$emit("MediaPlay", {});
      //异步打开
      setTimeout(() => {
        try {
          const method = this._data.$prototype.$app.$refs.fullScreenVideo
            .requestFullscreen
            ? "requestFullscreen"
            : "webkitEnterFullScreen";
          this._data.$prototype.$app.$refs.fullScreenVideo[method]();
          this._data.$prototype.$app.$refs.fullScreenVideo.play();

          setTimeout(() => {
            this._data.$prototype.$app.fullScreenVideoLoad = false;
          }, 100);
        } catch (e) {
          console.log(e);
        }
      }, 200);
    },
    dateFormat: (originVal, format = "y-m-d h:i:s") => {
      const dt = new Date(originVal * 1000);

      const y = dt.getFullYear();
      const m = (dt.getMonth() + 1 + "").padStart(2, "0");
      const d = (dt.getDate() + "").padStart(2, "0");

      const h = (dt.getHours() + "").padStart(2, "0");
      const i = (dt.getMinutes() + "").padStart(2, "0");
      const s = (dt.getSeconds() + "").padStart(2, "0");

      const vals = {
        y,
        m,
        d,
        h,
        i,
        s,
      };
      Object.keys(vals).forEach((key) => {
        format = format.replace(key, parseInt(vals[key]));
      });

      return format;
    },
    parseLrc(lrc) {
      var oLRC = {
        ti: "", //歌曲名
        ar: "", //演唱者
        al: "", //专辑名
        by: "", //歌词制作人
        offset: 0, //时间补偿值，单位毫秒，用于调整歌词整体位置
        ms: [], //歌词数组{t:时间,c:歌词}
      };
      if (lrc.length == 0) return;
      var lrcs = lrc.split("\n"); //用回车拆分成数组
      for (let i in lrcs) {
        //遍历歌词数组
        lrcs[i] = lrcs[i].replace(/(^\s*)|(\s*$)/g, ""); //去除前后空格
        var t = lrcs[i].substring(
          lrcs[i].indexOf("[") + 1,
          lrcs[i].indexOf("]")
        ); //取[]间的内容
        var s = t.split(":"); //分离:前后文字
        if (isNaN(parseInt(s[0]))) {
          //不是数值
          for (let i in oLRC) {
            if (i != "ms" && i == s[0].toLowerCase()) {
              oLRC[i] = s[1];
            }
          }
        } else {
          //是数值
          var arr = lrcs[i].match(/\[(\d+:.+?)\]/g); //提取时间字段，可能有多个
          var start = 0;
          for (let k in arr) {
            start += arr[k].length; //计算歌词位置
          }
          var content = lrcs[i].substring(start); //获取歌词内容
          for (let k in arr) {
            let t = arr[k].substring(1, arr[k].length - 1); //取[]间的内容
            let s = t.split(":"); //分离:前后文字
            if (content == "") {
              continue;
            }
            oLRC.ms.push({
              //对象{t:时间,c:歌词}加入ms数组
              t: (parseFloat(s[0]) * 60 + parseFloat(s[1])).toFixed(3),
              c: content,
            });
          }
        }
      }
      oLRC.ms.sort(function(a, b) {
        //按时间顺序排序
        return a.t - b.t;
      });
      return oLRC;
    },
    loadAudio(config) {
      let context = new Audio();
      context.src = config.src;
      let playPromise;
      playPromise = context.play();
      if (playPromise) {
        playPromise
          .then((second) => {
            // 音频加载成功
            // 音频的播放需要耗时
            let interval = setInterval(() => {
              second--;
              if (second <= 0) {
                context.pause();
                clearInterval(interval);
              }
            }, 1000);
          })
          .catch((e) => {
            // 音频加载失败
            config.error && config.error(e);
          });
      }
      return context;
    },
  },
};
