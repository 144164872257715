import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      components: {
        head: () => import("@/layout/head"),
        default: () => import("@/views/home"),
        foot: () => import("@/layout/foot"),
      },
    },
    {
      path: "/home_course_info",
      components: {
        head: () => import("@/layout/head_orange"),
        default: () => import("@/views/home_course_info"),
        foot: () => import("@/layout/foot"),
      },
    },
    {
      path: "/about",
      components: {
        head: () => import("@/layout/head"),
        default: () => import("@/views/about"),
        foot: () => import("@/layout/foot"),
      },
    },
    {
      path: "/school",
      components: {
        head: () => import("@/layout/head"),
        default: () => import("@/views/school"),
        foot: () => import("@/layout/foot"),
      },
    },
    {
      path: "/news",
      components: {
        head: () => import("@/layout/head"),
        default: () => import("@/views/news"),
        foot: () => import("@/layout/foot"),
      },
    },
    {
      path: "/news_info",
      components: {
        head: () => import("@/layout/head_orange"),
        default: () => import("@/views/news_info"),
        foot: () => import("@/layout/foot"),
      },
    },
    {
      path: "/textbook",
      components: {
        head: () => import("@/layout/head"),
        default: () => import("@/views/textbook"),
        foot: () => import("@/layout/foot"),
      },
    },
    {
      path: "/textbook_info",
      components: {
        head: () => import("@/layout/head_orange"),
        default: () => import("@/views/textbook_info"),
        foot: () => import("@/layout/foot"),
      },
    },
    {
      path: "/download",
      components: {
        head: () => import("@/layout/head"),
        default: () => import("@/views/download"),
        foot: () => import("@/layout/foot"),
      },
    },
    {
      path: "/league",
      components: {
        head: () => import("@/layout/head"),
        default: () => import("@/views/league"),
        foot: () => import("@/layout/foot"),
      },
    },
    {
      path: "/activity",
      components: {
        head: () => import("@/layout/head"),
        default: () => import("@/views/activity"),
        foot: () => import("@/layout/foot"),
      },
    },
    {
      path: "/online_course",
      components: {
        head: () => import("@/layout/head"),
        default: () => import("@/views/online_course"),
        foot: () => import("@/layout/foot"),
      },
    },
    {
      path: "/mobile/home",
      components: {
        default: () => import("@/views/mobile/home"),
        foot: () => import("@/layout/mobile_foot"),
      },
    },
    {
      path: "/mobile/news_list",
      components: {
        default: () => import("@/views/mobile/news_list"),
        foot: () => import("@/layout/mobile_foot"),
      },
    },
    {
      path: "/mobile/news_info",
      components: {
        default: () => import("@/views/mobile/news_info"),
        foot: () => import("@/layout/mobile_foot"),
      },
    },
    {
      path: "/mobile/activity",
      components: {
        default: () => import("@/views/mobile/activity"),
        foot: () => import("@/layout/mobile_foot"),
      },
    },
    {
      path: "/mobile/join_now",
      components: {
        default: () => import("@/views/mobile/join_now"),
      },
    },
    {
      path: "/mobile/result",
      components: {
        default: () => import("@/views/mobile/result"),
      },
    },
    {
      path: "/mobile/course_list",
      components: {
        default: () => import("@/views/mobile/course_list"),
      },
    },
  ],
});

export default router;
