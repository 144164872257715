import Vue from "vue";
import App from "./App.vue";
const path = require("path");

Vue.config.productionTip = false;

import "amfe-flexible";

//VueX
import store from "@/store";

//Router
import router from "@/router";

//演示版则使用本地MOCK数据
if (process.env.NODE_ENV === "staging") {
  const { mockXHR } = require("../mock");
  mockXHR();
}

import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

//全局挂载API
import api from "./api";
Vue.prototype.$api = api;

//ElementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
Vue.prototype.$element = ElementUI;

import animated from "animate.css";
Vue.use(animated);

//SVG
import SvgIcon from "vue-svg-icon/Icon.vue";
Vue.component("SvgIcon", SvgIcon);

//wechat
import Wechat from "./wechat";
Vue.prototype.$wechat = Wechat;

//动态注册指令
let directiveFiles = require.context("./directive", true, /\.js$/);
//汇聚所有自定义的API
directiveFiles.keys().forEach((key) => {
  const name = path.basename(key, ".js");
  Vue.directive(name, directiveFiles(key).default);
});

Vue.prototype.$app = null;
import mixin from "./mixin";
Vue.mixin(mixin);

new Vue({
  router,
  store,
  render: (h) => h(App),
  data: {
    eventHub: new Vue(),
  },
}).$mount("#app");
