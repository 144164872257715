const path = require("path");
const customApiList = {};
import request from "./request";

let apiFiles = require.context("./modules", true, /\.js$/);
//汇聚所有自定义的API
apiFiles.keys().forEach(key => {
  var dir = path.dirname(key);
  if (dir === ".") {
    return;
  }
  dir = dir.replace("./", "");
  if (!customApiList[dir]) {
    customApiList[dir] = {};
  }

  const name = path.basename(key, ".js");
  customApiList[dir][name] = apiFiles(key);
});

export default function(url, data = {}, method = "POST", headers = {}) {
  //先检查是否自定义API，
  let currCustomApi = customApiList;
  url.split("/").map(item => {
    if (item === "") {
      return;
    }
    if (!currCustomApi[item]) {
      return;
    }
    //递归
    currCustomApi = currCustomApi[item];
  });

  let api = null;
  //优先使用自定义API

  if (typeof currCustomApi == "function") {
    api = currCustomApi();
  }

  //通用API
  if (!api) {
    api = request({
      url,
      method,
      data,
      headers
    });
  }

  return api;
}
