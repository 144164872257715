import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000,
});

service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers["X-Token"] = store.getters.userToken;
    }
    return config;
  },
  (error) => {
    // console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    // console.log("API", {
    //   url: response.config.url,
    //   headers: response.headers,
    //   type: response.config.method,
    //   request: response.config.data,
    //   response: response.data,
    // });

    const res = response.data;
    switch (res.code) {
      case 200:
        //200调用自己处理结果
        return Promise.resolve({
          msg: res.msg,
          data: res.data,
          code: res.code,
        });
      case 500:
        //500只弹窗
        Message({
          message: res.msg || "Error",
          type: "error",
          duration: 1500,
          offset: document.documentElement.clientHeight * 0.1,
        });
        return Promise.reject(res);
      case 501:
        MessageBox.confirm("您已登出，请重新登录", "登出提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          store.dispatch("user/resetToken").then(() => {
            location.reload();
          });
        });
        return Promise.reject(res);
      // break;
      default:
        // console.log(res.msg);
        return Promise.reject({
          msg: res.msg,
          data: res.data,
          code: res.code,
        });
    }
  },
  (error) => {
    // console.log("err" + error);
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject({
      msg: error.message,
      data: {},
      code: 0,
    });
  }
);

export default service;
