<template>
  <div id="app">
    <router-view name="head" />
    <router-view />
    <router-view name="foot" />

    <!-- 全屏视频 -->
    <video id="full-screen-video" :src="fullScreenVideoSrc" ref="fullScreenVideo"></video>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fullScreenVideoLoad: false,
      fullScreenVideoSrc: "",
      transitionName: "",
      userAgent: "",
    };
  },
  created() {
    if (location.search.length > 0) {
      const data = location.search.substr(1).split("=");
      if (data[0] == "news_info") {
        this.$router.replace({
          path: "/mobile/news_info",
          query: { id: data[1] },
        });
      }
    } else {
      //记录当前UA，如果UA不一致再执行
      this.userAgent = navigator.userAgent;

      this.autoRouter();
      window.onresize = () => {
        // if (this.userAgent != navigator.userAgent) {
        this.autoRouter();
        // }
      };

      //在微信中打开
      if (
        navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
        "micromessenger"
      ) {
        this.$wechat.updateShareData();
      }
    }

    this.$app = this;
    console.log(this.$app);
  },
  methods: {
    autoRouter() {
      if (this.isMobile() || document.documentElement.clientWidth < 768) {
        if (location.hash.substr(1, 7) != "/mobile") {
          this.$router.replace({ path: "/mobile/home" });
        }
      } else {
        if (location.hash.substr(1, 7) == "/mobile") {
          this.$router.replace({ path: "/home" });
        }
      }
    },
  },
  watch: {
    //使用watch 监听$router的变化
    $route(to, from) {
      //如果to索引大于from索引,判断为前进状态,反之则为后退状态
      if (to.meta.index > from.meta.index) {
        //设置动画名称
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#full-screen-video {
  position: fixed;
  width: 10px;
  height: 10px;
  left: -10px;
}
</style>
